.projectSelector {
  position: relative;  // not an ideal way to style a component on react since this is child.
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust this value based on your layout */
}

.projectSelector::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-image: url("../../assets/img/edai-logo-small.png"); /* Adjust the image path and name */
  background-repeat: no-repeat;
  background-position: left top;
  z-index: -1;
  margin: 10px;
}

.projectSelector::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-image: url("../../assets/img/banner-hand.png"); /* Adjust the image path and name */
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: -1;
}

.card {
  display: flex;
  justify-items: center;
  width: 600px; /* Adjust this value based on your layout */
  max-height: 600px;
  text-align: start;
  background-color: #081624 !important;
  opacity: 0.9;
  color: #fcfbfb;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto !important; 
}

.projectSelectorTitle {
  margin-top: 10px; /* Adjust this value based on your layout */
  margin-bottom: 20px !important;
  font-size: large;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: #fcfbfb;
  // color: #14304c;
}

.projectList {
  overflow: auto; /* Make the items scrollable */
}

.projectListItem {
  cursor: pointer;
  background-color: #081624 !important;
  transition: background-color 0.3s ease;
  border: 0.5px solid #14304c !important;


  &:hover {
    background-color: #0e2942 !important;
    border-bottom: 0.5px solid #f0b93b !important;
  }

  .itemTitle {
    font-size: medium;
    font-weight: normal;
    margin-bottom: 0px !important;
  }
}
