$primary_t1: #0E2942;
$primary_t2: #000;
$primary_t3: #fff;
$primary_t4: #F0B93B;
$primary_t5: #081624;
$primary_t6: #ffc848;
$primary_t7: #081624;
$primary_t8: #14304c;
$primary_t9: #6c757d;
$primary_t10: linear-gradient(0deg, rgba(14, 38, 63, 1), rgba(17, 57, 91, 1));
$primary_t11: #0c2030;
$primary_t12: #3981C8;
$primary_t13: #f8f8f8;
  
  .fab {
    position: fixed;
    right: 10px;
    top: 16px;
    border-radius: 50%;
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  button {
    background: transparent;
    border: 0;
    cursor: pointer;
    font-family: inherit;
  }
  
  .fab > button {
    position: relative;
    z-index: 1;
    display: grid;
    place-items: center;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    border: 0;
    background: $primary_t4;
    color: #f9f9f9;
    transition: 0.2s;
    font-size: larger;
  }
  
  .menu {
    margin-right: 3px;
    margin-top: 8px;
    display: grid;
    place-content: center;
    gap: 16px;
    width: 380px;
    scale: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.375s;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .fab.open > button {
    rotate: 45deg;
  }
  
  .fab.open .menu {
    scale: 1;
    translate: 0;
    opacity: 1;
    visibility: visible;
  }
  
  .menu > button {
    position: relative;
    display: grid;
    place-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $primary_t4;
    color: $primary_t3;
    transition: 0.3s;
    font-size: larger;
    border-color: $primary_t5;
    border-width: 1px;
  }
  
  .menu > button:hover {
    background: $primary_t4;
    color: $primary_t5;
  }
  
  .menu > button > span {
    position: absolute;
    top: 55px;
    white-space: nowrap;
    translate: 10px 0;
    opacity: 0;
    visibility: hidden;
    font-size: 18px;
    transition: 0.5s;
    font-weight: bold; 
    color: $primary_t3;
    margin-top: 3px;
  }

  .diagnosis-span {
    right: -19px;
  }

  .pathology-span {
    right: -28px;
  }
  
  .menu > button:hover > span {
    translate: 0;
    opacity: 1;
    visibility: visible;
  }

  .tools-icon {
    float: right;
    margin-top: 5px;
  }
  
  .slide-out-div {
    position: absolute !important;
    top: 0;
    height: calc(100vh - 135px);
    border-width: 1px;
    border-color: $primary_t12;
    transition: all 0.5s ease-in-out;
    padding-right: 0px !important;
    z-index: 1000 !important;
  }
  
  .slide-out-div.active {
    left: 0;
    padding-left: 14px !important;
    margin-top: 111px;
  }

  .slider-body {
    min-height: 100%;
    height: auto;
    background-color: $primary_t8;
    border-width: 1px;
    border-color: $primary_t12;
    border-radius: 20px;
    padding-right: 0px !important;
    margin-right: 23px !important;
  }

  .slider-close {
    padding: 6px 8px;
    color: $primary_t3;
  }

  .dropdown-container {
    padding: 14px 10px 10px 10px;
  }

  .pathology-flex-slider {
    margin-top: 16px;
    align-items: start !important;
  }

  .diagnosis-flex-slider{
    margin-left: 10px;
    padding-top: 8px;
    display: flex !important;
    align-items: center !important;
  }

  .controls-flex {
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap; 
    align-items: flex-start; 
    justify-content: space-between;
  }

  .controls-flex-imageset {
    // margin: 0 10px;
    display: flex;
    flex-wrap: nowrap; 
    align-items: flex-start; 
    // justify-content: space-between;
  }

  .controls-flex-imageset button {
    margin-right: 10px;
  }

  .controls-flex button {
    margin: 0 5px;
  }
    
  .dropdown-first-select {
    margin: 10px 5px 10px 10px;
  }

  .dropdown-first-select button{
    margin-bottom: 24px !important;
  }

  .dropdown-second-select {
    margin: 10px 5px 10px 5px;
  }

  .dropdown-select {
    margin: 0 10px 0 0;
  }

  .reject-dropdown {
    margin-left: 0px;;
  }

  .dropdown > button {
    padding-left: 16px !important;
    padding-right: 16px !important; 
  }

  .dropdown-menu {
    max-height: 600px;
    overflow: auto !important;
  }

  .input-dropdown-container {
    position: relative;
  }

  .dropdown-select .dropdown button{
    margin-bottom: 24px !important;
  }

  .slider-button {
    margin: 0 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    cursor: pointer;
  }
  
  input:focus {
    outline: none;
  }

  input[type=checkbox]:hover {
    border: 1px solid $primary_t4 !important;
  }

  input[type=checkbox]:hover + label {
    color: $primary_t4 !important;

  }
  
  ul.options-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: $primary_t3;
    z-index: 1;
  }
  
  ul.options-list li {
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
  }
  
  ul.options-list li:hover {
    background-color: $primary_t13;
  }
  
.chip {
  margin: 5px !important;
  color:#3981C8;
}

.stop-button {
 margin-left: 5px;;
 font-size: larger !important;
}

.stop-button-icon {
  display: none;
}
.stop-button-text {
  display: inline-block;
}

.zero-count-prompt {
  background-color: $primary_t7;
  color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  position: absolute;
  top: 50vh;
  left: 44vw;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
  
.zero-count-prompt-button {
  width: 86px;
  height: 38px;
  color: #fff;
  margin: 5px;
  padding: 6px;
  border-radius: 6px;
  background-color: green;
  float: right;
}

.zero-count-prompt-button button {
  color: #ccc;
}

.prompt {
  background-color: $primary_t7;
  color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.prompt button {
  width: 70px;
  height: 38px;
  color: #fff;
  margin: 5px;
  padding: 5px;
  border-radius: 6px;
}

.prompts {
  display: flex !important;
  flex-direction: row-reverse;
  margin-top: 10px;
}

.prompts > button:nth-child(1) {
  background-color: red;
}

.prompts > button:nth-child(2) {
  background-color: green;
}

.toggle-container {
  text-align: center;
  float: right;
  margin: 12px 12px 0 0;
  display:flex;
}

.toggle-switch {
  position: relative;
  width: 55px;
  height: 24px;
  text-align: left;
  margin: 8px;
  background-color: transparent; /* Clear track background */
  flex-direction: row;
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid $primary_t1; /* White outline */
  border-radius: 20px;
  background-color: $primary_t1; /* Default red track */
}

.inner {
  opacity: 0; /* Hide inner text */
}

.switch {
  display: block;
  width: 28px;
  height: 28px; /* Taller than the track */
  background: #fff; /* White circle */
  position: absolute;
  top: 0px; /* Adjust to center vertically */
  bottom: 0;
  right: 28px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
  text-align: center; /* Ensure icon centers */
  line-height: 32px; /* Align icon vertically */
}

.switch > svg {
  margin-bottom: 1px;
}

.checkbox:checked + .label {
  background-color: $primary_t1; /* Green track for locked */
  border: 2px solid $primary_t1;
}

.inner {
  display: inline-block;
}

.checkbox:checked + .label .switch {
  right: 0px;
  background: #fff; /* Maintain white circle */
}

.custom-checkbox {
  transform: scale(1.5); /* Scale checkboxes */
  margin-right: 5px;
  vertical-align: middle;
}

.tag-label {
  color: white;
  margin-right: 15px;
  margin-left: 7px;
  margin-top: 7px;
  white-space: nowrap; /* Prevent wrapping */
  max-width: 150px; 
  display: inline-block;
  vertical-align: middle;
}

.tag-container {
  display: flex;
  align-items: center;
  margin: 5px auto;
  flex-wrap: nowrap; /* Ensure the container doesn't wrap */
}

@media (max-width : 1366px) {
  .next-set{
    margin-bottom: 10px !important;
  }

  .stop-button {
    width: 38px;
    height: 38px;
  }

  .stop-button-icon {
    display: inline-block;
  }

  .stop-button-text {
    display: none;
  }

  .remove-icon {
    display: none !important;
  }

  .rc-slider {
    margin-top: 10px !important;
  }

  .diagnosis-flex-slider{
    margin-left: 0px;
    padding-top: 8px;
    display: flex !important;
    align-items: center !important;
  }

  .dropdown-select {
    margin: 0px 10px !important;
  }

  .reject-dropdown {
    margin-left: 0 !important;
  }

  .dropdown-select button {
    margin-left: 0 !important;
  }

  .controls-flex-imageset {
    margin-left: 0px !important;
    display: flex;
    flex-wrap: wrap; 
    align-items: flex-start; 
  }

  .controls-flex-imageset button {
    margin-right: 5px;
  }

  .controls-flex {
    margin-left: 0px !important;
    display: flex;
    flex-wrap: nowrap; 
    align-items: flex-start; 
  }

  .controls-flex button {
    padding: 6px 8px !important;
    margin-left: 0px !important;
  }

  .dropdown-first-select .dropdown button{
    margin-bottom: 10px !important;
  }
}








